import vestingAbi from "../contract/vesting-abi.json";
import config from "../config";
import { ethers } from "ethers";
import { useEffect, useState } from "react";

export type VestingInfoType = {
  claimed: number;
  totalVestingTokens: number;
  startDate: string;
  endDate: string;
  initialUnlock: number;
  available: number;
};

const emptyVestingInfo: VestingInfoType = {
  claimed: 0,
  totalVestingTokens: 0,
  startDate: "-",
  endDate: "-",
  initialUnlock: 0,
  available: 0
};

export function useVesting() {
  const provider = new ethers.providers.JsonRpcProvider(config.rpcUrl);
  const vestingContract = new ethers.Contract(
    config.vestingContract,
    vestingAbi,
    provider
  );

  const [vestingInfo, setVestingInfo] = useState<VestingInfoType[]>([emptyVestingInfo]);
  const [availableToClaim, setAvailableToClaim] = useState(0);
  const [enabled, setEnabled] = useState(false);

  useEffect(()=>{
  }, [vestingInfo]);

  function isEnabled():void {
      vestingContract.functions
      .isEnabled()
      .then((re) => {
        setEnabled(re);
      })
  }

  function getAvailable(account: string|undefined):void {
    if(!account) return;
    if (ethers.utils.isAddress(account)) {
      vestingContract.functions
      .batchAvailable(account)
      .then((re) => {
        const ret: ethers.BigNumber = re[0];
        setAvailableToClaim(parseInt(ethers.utils.formatEther(ret)))
      })
    }
  }

  async function getAvailableForSlots(account: string, slots: number) {
    const availableSlots:number[] = [];
    for(let i=0; i<slots; i++){
      const [ret]:[ethers.BigNumber] = await vestingContract.functions.available(account, i);
      availableSlots[i] = parseInt(ethers.utils.formatEther(ret));
    }
    return availableSlots;
  }

  function getInfo(account: string|undefined):void {
    if(!account) return;
    if (ethers.utils.isAddress(account)) {
      vestingContract.functions
        .batchVestingInfo(account)
        .then((re) => {
          getAvailableForSlots(account,re[0].length).then((availableForSlots) => {
            const ret: any[] = re[0];
            const retx: VestingInfoType[] = ret.map((el, ind) => { 
             return ({
             totalVestingTokens: Math.floor(
               parseInt(ethers.utils.formatEther(el[0]))
             ),
             claimed: Math.floor(parseInt(ethers.utils.formatEther(el[1]))),
             initialUnlock: Math.floor(
               parseInt(ethers.utils.formatEther(el[2]))
             ),
             startDate: el[3].toNumber() ? new Date(el[3].toNumber() * 1000).toLocaleString() : "-",
             endDate: el[4].toNumber() ? new Date(el[4].toNumber() * 1000).toLocaleString() : "-",
             available: availableForSlots[ind],
           })});
             if(retx.length > 0) setVestingInfo(retx);
             else setVestingInfo([emptyVestingInfo])
           })
           .catch((err) => {
             console.log(err);
           })
          })



    }
  }

  return { getInfo, vestingInfo, getAvailable, availableToClaim, enabled, isEnabled};
}
