import { NextPage } from "next";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import PageHeader from "../components/PageHeader";
import { useTranslations } from "../hooks/useTranslations";
import Head from "next/head";
import InfoSection from "../components/Claim/InfoSection";
import ClaimingPanel from "../components/Claim/ClaimingPanel";

const Claim: NextPage = () => {

  const { claim } = useTranslations();
  const {
    page_title,
    page_header_title,
    breadcrumbs_title,
  } = claim;

  return (
    <>
      <Head>
        <title>{page_title}</title>
      </Head>
      <Breadcrumbs title={breadcrumbs_title} />
      <PageHeader
        title={page_header_title}
        show_countdown={true}
        show_live={true}
      />
      <section className="section-claim">
          <ClaimingPanel></ClaimingPanel>
          <InfoSection></InfoSection>
      </section>
    </>
  );
};

export default Claim;
