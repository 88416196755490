import { Button, Card, Form, ProgressBar } from "react-bootstrap";
import { useTranslations } from "../../hooks/useTranslations";
import { useEffect, useState } from "react";
import config from "../../config";
import { useVesting } from "../../hooks/useVesting";
import { useClaim } from "../../hooks/useClaim";
import { useAccount, useProvider, useSigner } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";

export default function ClaimingPanel() {
  const { chainNetworkSwitch } = config;
  const { claim, header: headerSection } = useTranslations();
  const { claimFunk, isWaitingForClaim } = useClaim();
  const { openConnectModal } = useConnectModal();
  

  const {
    header,
    time_start,
    time_end,
    vested_tokens,
    initial_unlock,
    total_tokens,
    available_to_claim,
    claimed,
    claim_button,
    button_connect,
    vesting,
  } = claim.claiming_panel;
  const { address: account, isConnected: isAuthenticated } = useAccount();
  const { data: signer } = useSigner();

  const { getInfo, vestingInfo, getAvailable, availableToClaim, isEnabled, enabled } = useVesting();
  const [ vestingIndex, setVestingIndex ] = useState(0);

  useEffect(()=>{
    if(isAuthenticated && account){
      getInfo(account);
    }
  },[isAuthenticated, account, isWaitingForClaim]);

  useEffect(()=>{
    if(vestingInfo.length > 0 && account && isAuthenticated){
      const interval = setInterval(() => {
        getAvailable(account);
        getInfo(account);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(()=>{
    if(vestingInfo.length > 0 && account && isAuthenticated){
        getAvailable(account);
    }
  }, [vestingInfo, account, isAuthenticated, availableToClaim]);

  useEffect(()=>{
    if(vestingInfo.length > 0 && account && isAuthenticated){
      isEnabled();
    }
  }, [vestingInfo, account, isAuthenticated]);

  return (
    <div className="claiming-wrapper">
      <Card className="claiming-panel">
        <Card.Header className="py-4">
          <h6 className="card-heading">
            {header}
          </h6>
        </Card.Header>
        <Card.Body>
          <div className="main-info">
            <div className="main-info-element">
              <div className="main-info-element-title">
                <div className="main-info-element-title-item">
                  {claimed}  
                </div>
                <div className="main-info-element-title-item">
                  <span>{vestingInfo.reduce((acc, el) => acc + el.claimed, 0)} IGU</span> <span>/</span>{" "}
                  <span>{vestingInfo.reduce((acc, el) => acc + el.totalVestingTokens, 0)} IGU</span>{" "}
                </div>

              </div>
              <div className="main-info-element-progress mt-3">
                {" "}
                <ProgressBar
                  animated
                  variant="success"
                  now={
                    vestingInfo[0] ? (vestingInfo.reduce((acc, el) => acc + el.claimed, 0) / vestingInfo.reduce((acc, el) => acc + el.totalVestingTokens, 0)) * 100 : 0
                  }
                />
              </div>
            </div>
          </div>
          <div className="vesting-info mt-4">
          {vestingInfo.length > 1 &&
            <div>
              <span style={{marginRight: "10px"}}>
              {vesting} #: 
                </span>
              
            <Form.Select
            size="sm"
            className="d-inline w-auto me-1"
            onChange={(e) => setVestingIndex(Number(e.target.value))}
            value={vestingIndex}
          >
            {vestingInfo.map((el, key) => (
              <option key={key}>{key}</option>
            ))}
          </Form.Select>
          </div>
          }
            <div className="vesting-info-element mt-2">
              <div>{time_start}:</div>
              <div className="vesting-info-num">{vestingInfo[vestingIndex].startDate}</div>
            </div>
            <div className="vesting-info-element mt-2">
              <div>{time_end}:</div>
              <div className="vesting-info-num">{vestingInfo[vestingIndex].endDate}</div>
            </div>
            <div className="vesting-info-element mt-2">
              <div>{vested_tokens}:</div>
              <div className="vesting-info-num">
                {vestingInfo[vestingIndex].totalVestingTokens - vestingInfo[vestingIndex].initialUnlock} IGU
              </div>
            </div>
            <div className="vesting-info-element mt-2">
              <div>{initial_unlock}:</div>
              <div className="vesting-info-num">{vestingInfo[vestingIndex].initialUnlock} IGU</div>
            </div>
            {vestingInfo.length > 1 && <div className="vesting-info-element mt-2">
              <div>{available_to_claim}:</div>
              <div className="vesting-info-num">{vestingInfo[vestingIndex].available} IGU</div>
            </div>}
            <div className="vesting-info-element mt-2">
              <div>{claimed}:</div>
              <div className="vesting-info-num">{vestingInfo[vestingIndex].claimed} IGU</div>
            </div>
            <div className="vesting-line mt-2"></div>
            <div className="vesting-info-element mt-2">
              <div>{total_tokens}:</div>
              <div className="vesting-info-num">{vestingInfo[vestingIndex].totalVestingTokens} IGU</div>
            </div>
            <div className="vesting-info-element mt-5">
              <div>{available_to_claim}:</div>
              <div className="vesting-info-num">{availableToClaim} IGU</div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="text-end">
          {account && signer ? (
            <Button
              className="btn-buy text-uppercase w-100"
              variant=""
              disabled={availableToClaim < 1 || !enabled}
              onClick={() => claimFunk(0, signer, account)}
            >
              {claim_button}
            </Button>
          ) : (
            <Button
              className="btn-buy text-uppercase w-100"
              onClick={openConnectModal}
            >
              {button_connect}
            </Button>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
}