import React from "react";
import Link from "next/link";
import { Breadcrumb } from "react-bootstrap";
import { useTranslations } from "../hooks/useTranslations";
import SvgIcon from "./Icons/SvgIcon";

interface Props {
  pages?: { link: string; name: string }[];
  title: string;
}

export default function Breadcrumbs({ pages, title }: Props) {
  const { layout } = useTranslations();
  const { breadcrumb_main } = layout;
  return (
    <div className="page-breadcrumb">
      <Breadcrumb>
        <Link href="/" passHref>
          <Breadcrumb.Item>{breadcrumb_main}</Breadcrumb.Item>
        </Link>
        <SvgIcon type="arrow-right"/>
        {pages &&
          pages.map((page) => (
            <Link href={page.link} passHref key={page.link}>
              <Breadcrumb.Item>{page.name}</Breadcrumb.Item>
            </Link>
          ))}
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}
