import vestingAbi from "../contract/vesting-abi.json";
import { ethers, Signer } from "ethers";
import config from "../config";
import { useState } from "react";
import { useNotification } from "web3uikit";
import { useTranslations } from "./useTranslations";
import { useSigner } from "wagmi";

export function useClaim() {
  const dispatch = useNotification();

  const [isWaitingForClaim, setIsWaitingForClaim] = useState(false);

  const { notifications } = useTranslations();
  const { success, error, claim_error, claim_success } = notifications;

  function claimFunk(slot:number, signer:Signer,  account: string) {
    const vestingContract = new ethers.Contract(
      config.vestingContract,
      vestingAbi,
      signer
    );

    setIsWaitingForClaim(true);
    vestingContract.batchWithdraw(account)
      .then((res: any) => {
        console.log("claim result", res);
        res
        .wait(1)
        .then((waitResult: any) => {
          setIsWaitingForClaim(false);
          dispatch({
            type: "success",
            message: claim_success,
            title: success,
            icon: "check",
            position: "topL",
            
          });
          return(waitResult)
        })
      })
      .catch((err: any) => {
        console.log("claim error", err);
        dispatch({
          type: "error",
          message: claim_error + ". " + err.message,
          title: error,
          icon: "xCircle",
          position: "topL",
        });
      })
      .finally(() => {
        setIsWaitingForClaim(false);
      });
  }

  return { claimFunk, isWaitingForClaim };
}
