import React, { useContext } from "react";
import { Countdown } from "./Countdown";
import config from "../config";
import { useTranslations } from "../hooks/useTranslations";
// import { SaleStatusContext } from "../context/SaleStatusContext";

interface Props {
  title: string;
  show_countdown?: boolean;
  show_live?: boolean;
}

export default function PageHeader({ title, show_countdown, show_live }: Props) {
  const { startUTC, endUTC, priceIncreaseUTC } = config.sale;
  // const { isBeforeSale, isSaleInProgress } = useContext(SaleStatusContext);
  const { countdown } = useTranslations();

  return (
    <div className="page-header">
      {
        show_live ? (
            <span className="text-base me-2 mb-2 badge text-success bg-success-light float-start">
          <span className="icon-live"></span>
          <span className="ps-1">Live</span>
        </span>
        ) : ''
      }
      <h1 className="page-heading">{title}</h1>
        {show_countdown && <div className="countdown-inline">
      </div>}
    </div>
  );
}
