import { useTranslations } from "../../hooks/useTranslations";
import {faInfoCircle, faSquareCheck, faTriangleExclamation} from "@fortawesome/pro-light-svg-icons";
import {Card} from "react-bootstrap";
import React from "react";
import config from "../../config";
import SvgIcon from "../Icons/SvgIcon";

export default function InfoSection() {
  const { claim } = useTranslations();
  const { card_title, contract_address, features, warnings } = claim.info_section;

  return (
      <Card>
          <Card.Header>
              <h4 className="card-heading">{card_title}</h4>
          </Card.Header>
          <Card.Body>
              <div className="claiming-info">
                  <ul className="info-features">
                      <li>
                      <div className="info-features-icon"> 
                          <SvgIcon type={"checkmark"} />
                      </div>
                      <div className="info-features-details"> 
                          {contract_address}
                          <b>{` ${config.iguContract}`}</b>
                      </div>
                      </li>
                  </ul>

                  <ul className="info-features">
                      {features.map((e: string) => (
                          <li key={e}>
                          <div className="info-features-icon"> 
                              <SvgIcon type={"checkmark"} />
                          </div>
                          <div className="info-features-details"> 
                              {e}
                          </div>
                          </li>
                      ))}
                  </ul>

                  <ul className="info-features warning">
                      {warnings.map((e: string) => (
                          <li key={e}>
                            <div className="info-features-icon"> 
                              <SvgIcon type={"warning-yellow"} />
                            </div>
                            <div className="info-features-details"> 
                                {e}
                            </div>
                          </li>
                      ))}
                  </ul>
              </div>
          </Card.Body>
      </Card>
  );
}
